<script>
import _ from 'lodash'
import moment from 'moment'

import AppCard from '@/components/card/Card.vue'

export default {
  props: {
    modeProp: String,
    userLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  components: { AppCard },
  data() {
    return {
      oldData: null,
      newData: null,
      labelMap: {
        userId: 'ผู้ใช้งาน *',
        userName: 'ชื่อผู้ใช้งาน *',
        userLevel: 'ระดับผู้ใช้งาน *',
        department: 'แผนก',
        jobTitle: 'ตำแหน่งงาน',
        lastLoggedOnAt: 'เข้าสู่ระบบล่าสุด',
        lastPasswordChangedAt: 'แก้ไขรหัสผ่านล่าสุด',
        passwordExpiredAt: 'รหัสผ่านหมดอายุ',
        userExpiredAt: 'ผู้ใช้งานหมดอายุ',
        password: 'รหัสผ่าน *',
        passwordConfirm: 'ยืนยันรหัสผ่าน *'
      },
      keysForCreate: [
        'userId',
        'password',
        'passwordConfirm',
        'userLevel',
        'userName',
        'department',
        'jobTitle',
        'userExpiredAt'
      ],
      keysForUpdate: [
        'userName',
        'userLevel',
        'department',
        'jobTitle',
        'userExpiredAt'
      ]
    }
  },
  computed: {
    saveButtonEnable() {
      if (this.modeProp === 'CREATE') {
        const focusData = _.pick(this.newData, [
          'userId',
          'password',
          'passwordConfirm',
          'userLevel',
          'userName'
        ])
        if (
          Object.values(focusData).some(
            el => _.isNil(el) || (_.isString(el) && el.trim().length === 0)
          )
        ) {
          return false
        }
        return true
      }
      if (this.modeProp === 'EDIT') {
        return !_.isEmpty(this.checkDataDifference())
      }
      return false
    }
  },
  watch: {
    userLogonMasterProp() {
      this.initializeData()
    }
  },
  created() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      switch (this.modeProp) {
        case 'CREATE':
          const defaultData = {}
          this.keysForCreate.forEach(key => {
            defaultData[key] = null
          })
          this.oldData = { ...defaultData }
          this.newData = { ...defaultData }
          break
        case 'EDIT':
          if (this.userLogonMasterProp) {
            this.oldData = _.pick(this.userLogonMasterProp, this.keysForUpdate)
            this.oldData.userExpiredAt = moment(
              this.oldData.userExpiredAt
            ).format('YYYY-MM-DD')
            this.newData = { ...this.oldData }
          }
          break
        default:
          break
      }
    },
    checkDataDifference() {
      const payload = {}
      if (this.oldData && this.newData) {
        const focusKeys =
          this.modeProp === 'CREATE' ? this.keysForCreate : this.keysForUpdate
        focusKeys.forEach(key => {
          let oldValue = _.isString(this.oldData[key])
            ? this.oldData[key].trim()
            : null
          let newValue = _.isString(this.newData[key])
            ? this.newData[key].trim()
            : null

          if (_.isString(oldValue) && oldValue.trim().length === 0)
            oldValue = null
          if (_.isString(newValue) && newValue.trim().length === 0)
            newValue = null

          if (!_.isEqual(oldValue, newValue)) {
            payload[key] = _.isString(newValue) ? newValue.trim() : newValue
          }
        })
      }
      return payload
    },
    buttonClicked(type) {
      const payload = this.checkDataDifference()
      if (!_.isNaN(+payload.userLevel)) {
        payload.userLevel = +payload.userLevel
      }
      if (payload.userExpiredAt) {
        payload.userExpiredAt = moment(payload.userExpiredAt).toISOString()
      }

      switch (type) {
        case 'SAVE':
          if (this.modeProp === 'CREATE') {
            this.emitEvent('CREATE', payload)
          } else if (this.modeProp === 'EDIT') {
            this.emitEvent('UPDATE', payload)
          }
          break
        case 'CANCEL':
          if (this.modeProp === 'CREATE') {
            this.$router.push({
              name: 'Admin.UserLogonMasters'
            })
          }
          if (this.modeProp === 'EDIT') {
            this.$router.push({
              name: 'Admin.UserLogonMaster.Detail',
              params: { userId: this.$route.params.userId }
            })
          }
          break
        default:
          break
      }
    },
    emitEvent(eventType, payload) {
      switch (eventType) {
        case 'CREATE':
          this.$emit('createdInfo', payload)
          break
        case 'UPDATE':
          this.$emit('updatedInfo', payload)
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <app-card>
      <b-row>
        <!-- topic -->
        <b-col cols="12">
          <span class="font-weight-bold text-lg">
            ข้อมูลผู้ใช้งาน
          </span>
        </b-col>

        <!-- content -->
        <b-col cols="12" class="mt-2">
          <b-row
            class="mt-2"
            align-v="center"
            v-for="(value, key, index) in newData"
            :key="index"
          >
            <b-col cols="3">
              <span>{{ labelMap[key] }}</span>
            </b-col>
            <b-col cols="9" v-if="['userExpiredAt'].includes(key)">
              <b-form-datepicker
                id="example-datepicker"
                v-model="newData[key]"
                locale="th"
                :disabled="isLoadingProp"
              ></b-form-datepicker>
            </b-col>
            <b-col cols="9" v-else>
              <b-form-input
                v-model="newData[key]"
                :type="
                  ['password', 'passwordConfirm'].includes(key)
                    ? 'password'
                    : 'text'
                "
                :disabled="isLoadingProp"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-col>

        <!-- hover line -->
        <b-col cols="12">
          <hr />
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              class="mr-3 px-5"
              variant="primary"
              @click="buttonClicked('SAVE')"
              :disabled="!saveButtonEnable || isLoadingProp"
            >
              <b-spinner v-if="isLoadingProp" varinat="light" small></b-spinner>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-5"
              @click="buttonClicked('CANCEL')"
              :disabled="isLoadingProp"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-col>
      </b-row>
    </app-card>
  </div>
</template>

<style></style>
