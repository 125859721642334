<script>
import InfoForm from './create-edit-form-components/InfoForm.vue'
import GroupForm from './create-edit-form-components/GroupForm.vue'
import PermissionForm from './create-edit-form-components/PermissionForm.vue'

export default {
  props: {
    modeProp: String, // CREATE , EDIT
    userLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  components: {
    InfoForm,
    GroupForm,
    PermissionForm
  },
  methods: {
    createdInfoHandler(payload) {
      this.$emit('createdInfo', payload)
    },
    updatedInfoHandler(payload) {
      this.$emit('updatedInfo', payload)
    },
    updatedGroupHandler(payload) {
      this.$emit('updatedGroup', payload)
    },
    updatedPermissionHandler(payload) {
      this.$emit('updatedPermission', payload)
    }
  }
}
</script>

<template>
  <b-row>
    <b-col cols="12">
      <info-form
        :modeProp="modeProp"
        :userLogonMasterProp="userLogonMasterProp"
        :isLoadingProp="isLoadingProp"
        @createdInfo="createdInfoHandler"
        @updatedInfo="updatedInfoHandler"
      ></info-form>
    </b-col>
    <b-col cols="12" v-if="modeProp === 'EDIT'">
      <group-form
        class="mt-3"
        :userLogonMasterProp="userLogonMasterProp"
        :isLoadingProp="isLoadingProp"
        @updatedGroup="updatedGroupHandler"
      ></group-form>
    </b-col>
    <b-col cols="12" v-if="modeProp === 'EDIT'">
      <permission-form
        class="mt-3"
        :userLogonMasterProp="userLogonMasterProp"
        :isLoadingProp="isLoadingProp"
        @updatedPermission="updatedPermissionHandler"
      ></permission-form>
    </b-col>
  </b-row>
</template>

<style></style>
