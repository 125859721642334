<script>
import { chain, isEqual, pick, keys } from 'lodash'

import { toastMixins } from '@/mixins'
import AppCard from '@/components/card/Card.vue'
import authService from '@/services/auth/auth'

export default {
  mixins: [toastMixins],
  components: { AppCard },
  props: {
    userLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  data() {
    return {
      userId: null,
      oldSystemMenuAccesses: [],
      newSystemMenuAccesses: [],
      permissionFields: [
        'canView',
        'canAddNew',
        'canUpdate',
        'canDelete',
        'canPrint',
        'canApprove',
        'canCancel',
        'canEx1',
        'canEx2'
      ],
      permissionFieldMaps: {
        canView: 'เข้าดู',
        canAddNew: 'สร้าง',
        canUpdate: 'แก้ไข',
        canDelete: 'ลบ',
        canPrint: 'พิมพ์',
        canApprove: 'อนุมัติ',
        canCancel: 'ยกเลิก',
        canEx1: 'อื่น ๆ 1',
        canEx2: 'อื่น ๆ 2'
      },
      errMsg: null
    }
  },
  computed: {
    saveButtonEnable() {
      let foundDiff = false
      this.newSystemMenuAccesses.forEach(newAccess => {
        const { moduleId } = newAccess
        const oldAccess = this.oldSystemMenuAccesses.find(
          el => el.moduleId === moduleId
        )
        if (!isEqual(newAccess, oldAccess)) {
          foundDiff = true
        }
      })
      return foundDiff
    }
  },
  watch: {
    userLogonMasterProp() {
      this.initializeData()
    }
  },
  created() {
    this.userId = this.$route.params.userId
    this.initializeData()
  },
  methods: {
    async initializeData() {
      if (this.userLogonMasterProp) {
        try {
          const res = await authService.getSystemMenuAccesses({})
          const systemMenuAccesses = res.data.data.filter(
            el => el.userIdGroupCode === this.userLogonMasterProp.userId
          )
          this.oldSystemMenuAccesses = [
            ...systemMenuAccesses.map(el => ({ ...el }))
          ]
          this.newSystemMenuAccesses = [
            ...systemMenuAccesses.map(el => ({ ...el }))
          ]
        } catch (err) {
          this.errMsg = err.response.data.thMessage
        }
      }
    },
    pickFields(obj, fields) {
      return pick(obj, fields)
    },
    permissionButtonClicked(
      systemMenuAccessId,
      permissionKey,
      permissionValue
    ) {
      const clonedSystemMenuAccesses = [
        ...this.newSystemMenuAccesses.map(el => ({ ...el }))
      ]
      clonedSystemMenuAccesses.forEach(el => {
        if (el.id === systemMenuAccessId) {
          el[permissionKey] = !permissionValue
        }
      })
      this.newSystemMenuAccesses = clonedSystemMenuAccesses
    },
    buttonClicked(type) {
      switch (type) {
        case 'SAVE':
          const updatedSystemMenuAccesses = this.newSystemMenuAccesses.map(
            access => {
              const permissionObj = chain(access)
                .pick(this.permissionFields)
                .value()
              const allowPermissions = keys(permissionObj).filter(
                key => permissionObj[key]
              )
              const notAllowPermissions = keys(permissionObj).filter(
                key => !permissionObj[key]
              )
              return {
                systemMenuAccessId: access.id,
                allowPermissions,
                notAllowPermissions
              }
            }
          )

          this.$emit('updatedPermission', updatedSystemMenuAccesses)
          break
        case 'CANCEL':
          this.$router.push({
            name: 'Admin.UserLogonMaster.Detail',
            params: { userId: this.userId }
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<template>
  <div>
    <app-card>
      <b-row>
        <!-- topic -->
        <b-col cols="12">
          <span class="font-weight-bold text-lg">
            สิทธิ์ของผู้ใช้งาน
          </span>
        </b-col>

        <!-- content -->
        <!-- have accesses -->
        <b-col cols="12" class="mt-4" v-if="newSystemMenuAccesses.length > 0">
          <b-row
            v-for="systemMenuAccess in newSystemMenuAccesses"
            :key="systemMenuAccess.id"
            class="my-3"
          >
            <b-col cols="3">
              <span>{{ systemMenuAccess.moduleName }}</span>
            </b-col>
            <b-col cols="9">
              <b-button
                v-for="(value, key) in pickFields(
                  systemMenuAccess,
                  permissionFields
                )"
                :key="key"
                :variant="!!value ? 'primary' : 'outline-primary'"
                size="sm"
                class="mr-2"
                @click="
                  permissionButtonClicked(systemMenuAccess.id, key, value)
                "
                :disabled="isLoadingProp"
              >
                {{ permissionFieldMaps[key] }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- no access -->
        <b-col cols="12" class="mt-4" v-else>
          <span>ไม่พบการกำหนดสิทธิ์การเข้าถึงโดยตรงสำหรับผู้ใช้งานรายนี้</span>
        </b-col>

        <!-- hover line -->
        <b-col cols="12">
          <hr />
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              class="mr-3 px-5"
              variant="primary"
              @click="buttonClicked('SAVE')"
              :disabled="!saveButtonEnable || isLoadingProp"
            >
              <b-spinner v-if="isLoadingProp" varinat="light" small></b-spinner>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-5"
              @click="buttonClicked('CANCEL')"
              :disabled="isLoadingProp"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-col>
      </b-row>
    </app-card>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
