<script>
import { isEqual, orderBy } from 'lodash'

import AppCard from '@/components/card/Card.vue'
import authService from '@/services/auth/auth'
import { toastMixins } from '@/mixins'

export default {
  mixins: [toastMixins],
  props: {
    userLogonMasterProp: Object,
    isLoadingProp: Boolean
  },
  components: {
    AppCard
  },
  data() {
    return {
      isLoading: false,
      userId: null,
      allGroupCodes: [],
      oldSelectedGroupCodes: [],
      newSelectedGroupCodes: [],
      errMsg: null
    }
  },
  computed: {
    saveButtonEnable() {
      const oldGroups = orderBy(
        this.oldSelectedGroupCodes.map(el => el.groupCode),
        ['groupCode'],
        ['asc']
      )
      const newGroups = orderBy(
        this.newSelectedGroupCodes.map(el => el.groupCode),
        ['groupCode'],
        ['asc']
      )
      return !isEqual(oldGroups, newGroups)
    }
  },
  watch: {
    userLogonMasterProp() {
      this.initializeUserGroupCodes()
    }
  },
  async created() {
    this.userId = this.$route.params.userId
    await Promise.all([this.fetchAllGroupCodes()])
    this.initializeUserGroupCodes()
  },
  methods: {
    async fetchAllGroupCodes() {
      this.isLoading = true
      try {
        const res = await authService.getGroupLogonMasters({})
        this.allGroupCodes = res.data.data.map(({ groupCode, groupName }) => ({
          groupCode,
          groupName
        }))
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      }
      this.isLoading = false
    },
    initializeUserGroupCodes() {
      if (this.userLogonMasterProp) {
        const { groups } = this.userLogonMasterProp
        this.oldSelectedGroupCodes = groups.map(({ groupCode, groupName }) => ({
          groupCode,
          groupName
        }))
        this.newSelectedGroupCodes = groups.map(({ groupCode, groupName }) => ({
          groupCode,
          groupName
        }))
        return
      }
    },
    buttonClicked(type, payload = {}) {
      switch (type) {
        case 'GROUP':
          this.groupSelected(payload)
          break
        case 'SAVE':
          this.$emit(
            'updatedGroup',
            this.newSelectedGroupCodes.map(el => el.groupCode)
          )
          break
        case 'CANCEL':
          this.$router.push({
            name: 'Admin.UserLogonMaster.Detail',
            params: { userId: this.userId }
          })
          break
        default:
          break
      }
    },
    groupSelected(group) {
      const { groupCode } = group
      const alreadySelected = this.newSelectedGroupCodes.find(
        el => el.groupCode === groupCode
      )
      if (alreadySelected) {
        this.newSelectedGroupCodes = this.newSelectedGroupCodes.filter(
          el => el.groupCode !== groupCode
        )
        return
      }
      this.newSelectedGroupCodes.push(group)
    }
  }
}
</script>

<template>
  <div>
    <app-card>
      <b-row>
        <!-- topic -->
        <b-col cols="12">
          <span class="font-weight-bold text-lg">
            กลุ่มผู้ใช้งาน
          </span>
        </b-col>

        <!-- content -->
        <b-col cols="12" class="mt-2">
          <b-row>
            <b-col
              cols="6"
              sm="4"
              md="3"
              xl="2"
              class="mt-2"
              v-for="group in allGroupCodes"
              :key="group.groupCode"
            >
              <b-button
                :variant="
                  newSelectedGroupCodes.find(
                    el => el.groupCode === group.groupCode
                  )
                    ? 'primary'
                    : 'outline-primary'
                "
                @click="buttonClicked('GROUP', group)"
                class="w-100 h-100"
                size="sm"
                :disabled="isLoadingProp || isLoading"
              >
                <span>{{ group.groupName }}</span>
                <br />
                <span class="text-xs">( {{ group.groupCode }} )</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <!-- hover line -->
        <b-col cols="12">
          <hr />
        </b-col>

        <!-- buttons -->
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              class="mr-3 px-5"
              variant="primary"
              @click="buttonClicked('SAVE')"
              :disabled="!saveButtonEnable || isLoadingProp || isLoading"
            >
              <b-spinner
                v-if="isLoadingProp || isLoading"
                varinat="light"
                small
              ></b-spinner>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-5"
              @click="buttonClicked('CANCEL')"
              :disabled="isLoadingProp || isLoading"
            >
              ยกเลิก
            </b-button>
          </div>
        </b-col>
      </b-row>
    </app-card>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
